<template>
  <div class="container">
    <el-form class="form" ref="ruleForm" :inline="true" :model="formData" :rules="rules">
      <el-row>
        <!-- 旧手机号验证码 -->
        <el-col :span="24">
          <el-form-item prop="mobile" label="原手机号">
            <el-input v-model="formData.mobile" style="width: 228px !important;" placeholder="请输入原手机号"
                      @input="inputOldNum" disabled clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item prop="oldVerifyCode" label="验证码" class="wxq_input_width">
            <el-input v-model="formData.oldVerifyCode" placeholder="请输入验证码" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-button class="yh_btn bg_blue" @click="getOldVerifyCode"
                   :class="isdisabled === true ? 'oldVerifyCode':'yh_btn'" :disabled="isdisabled">{{ oldMsg }}
        </el-button>
        <!-- 新手机号 -->
        <el-col :span="24">
          <el-form-item prop="phone" label="新手机号">
            <el-input v-model="formData.phone" style="width: 228px !important;" placeholder="请输入更换的手机号"
                      @input="inputNewNum" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item prop="verifyCode" label="验证码" class="wxq_input_width">
            <el-input v-model="formData.verifyCode" placeholder="请输入验证码" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-button class="yh_btn bg_blue" @click="getNewCode"
                     :class="isDisabled === true ? 'getVerification':'yh_btn'" :disabled="isDisabled">{{ msg }}
          </el-button>
        </el-col>
      </el-row>
      <div class="dialog_submit">
        <el-button class="btn btn_cancle" @click="cancel">取消</el-button>
        <el-button class="btn btn_blue" @click="submit('ruleForm')">确认</el-button>
      </div>
    </el-form>

  </div>
</template>

<script>
import API from '@/apis/studentsEndAPI/personalCenterAPI'
export default {
  name: 'changePassword',
  data() {
    var changePass = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入手机号'))
      } else {
        let reg = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
        if (!reg.test(value)) {
          callback(new Error('请输入正确手机号'))
        } else {
          callback()
        }
      }
    }
    return {
      phone: '',
      msg: '获取验证码',
      oldMsg: '获取验证码',
      seconds: 60,
      oldSeconds: 60,
      isDisabled: false,
      isdisabled: false,//old
      width: '',
      formData: {
        phone: '',
        verifyCode: '',
        mobile: '',
        oldVerifyCode: '',
      },
      rules: {
        phone: [{required: true, validator: changePass, trigger: "blur"}],
        verifyCode: [{required: true, message: '请输入验证码', trigger: "blur"}],
        mobile: [{required: true, validator: changePass, trigger: "blur"}],
        oldVerifyCode: [{required: true, message: '请输入验证码', trigger: "blur"}]
      },
      reg: /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/,
    }
  },
  mounted() {
    this.formData.mobile = JSON.parse(sessionStorage.getItem('profile')).phone
    if (this.formData.mobile == '') {
      this.isdisabled = true
    } else {
      this.isdisabled = false
    }
    this.isDisabled = true
  },
  methods: {
    async getOldVerifyCode() {//获取旧验证码
      let params = {
        mobile: this.formData.mobile,
        type: '3',//3旧手机号验证码
        oldVerifyCode: '',
      }
      let data = await API.getVerifyCode(params.mobile, params.type, params.oldVerifyCode) //364577
      if (data.msg == '操作成功!') {
        this.isdisabled = true
        this.timmer = window.setInterval(() => {
          if (this.oldSeconds == 1) {
            this.isdisabled = false
            window.clearInterval(this.timmer)
            this.oldMsg = '重新发送'
            this.oldSeconds = 60
          } else {
            this.isdisabled = true
            this.oldSeconds -= 1
            this.oldMsg = this.oldSeconds + 's'
          }
        }, 1000)
      }
    },
    async getNewCode() {//获取新验证码
      let params = {
        mobile: this.formData.phone,
        oldVerifyCode: this.formData.oldVerifyCode,
        type: '4'//新手机号验证码
      }
      let data = await API.getVerifyCode(params.mobile, params.type, params.oldVerifyCode)
      if (data.msg == '操作成功!') {
        this.isDisabled = true
        this.timmerOut = window.setInterval(() => {
          if (this.seconds == 1) {
            this.isDisabled = false
            window.clearInterval(this.timmerOut)
            this.msg = '重新发送'
            this.seconds = 60
          } else {
            this.isDisabled = true
            this.seconds -= 1
            this.msg = this.seconds + 's'
          }
        }, 1000)
      }
    },
    inputOldNum() {
      if (!this.reg.test(this.formData.phone)) {
        this.isdisabled = true
      } else {
        this.isdisabled = false
      }
    },
    inputNewNum() {
      if (!this.reg.test(this.formData.phone)) {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
    },
    async submit(ruleForm) {
      this.$refs[ruleForm].validate(async valid => {
        if (valid) {
          let params = {
            oldVerifyCode: this.formData.oldVerifyCode,
            phone: this.formData.phone,
            verifyCode: this.formData.verifyCode
          }
          let data = await API.changePhone(params)
          if (data.msg == '修改绑定手机成功') {
            this.$emit('closeDialog', false)
          }
        }
      })
    },
    cancel() {
      this.$emit('closeDialog', false)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
::v-deep {
  .yh_btn {
    margin-left: 20px;
    color: #fff;
  }
  .wxq_input_width {
    .el-input {
      width: 228px !important;
    }
  }
  // .el-input.is-disabled .el-input__inner{
  //   width: 110%;
  // }
  // .el-input__inner{
  //   width: 100%;
  // }
}
.getVerification {
  border: none !important;
  background: #ccc !important;
  color: #fff !important;
}
.oldVerifyCode {
  background: #ccc !important;
  color: #fff !important;
}
</style>
