<template>
  <div class="yh_wrapper">
    <ul class="yh_wrapper_ul">
      <template v-for="(item ,ind) in titleList">
        <li
          :key="item.id"
          :class="active == ind ? 'active' : 'yh_li'"
          @click="navClick( ind)"
          >{{item}}</li>
      </template>
    </ul>
    <a-security v-show="isAsecurity"></a-security>
    <a-data v-show="isAdata"></a-data>
  </div>
</template>

<script>
import aSecurity from './aSecurity'
import aData from './aData'
export default {
  name: 'account',
  components: {
    aSecurity,
    aData
  },
  data() {
    return {
      isAsecurity: false,
      isAdata: false,
      active: 0,
      titleList: ['账户安全','基本资料']
    }
  },
  created() {
    this.isAsecurity = true
  },
  methods: {
    navClick(ind) {
      this.active = ind
      if(ind == 0) {
        this.isAsecurity = true
        this.isAdata = false
      }else {
        this.isAsecurity = false
        this.isAdata = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$color: var(--colorBlue);
.yh_wrapper {
	padding: 0 30px;
	width: 100%;
	.yh_wrapper_ul {
		display: flex;
		margin-bottom: 24px;

		.yh_li {
			margin-right: 30px;
			width: 64px;
			height: 44px;
			color: #91929e;
			font-weight: 400;
			font-size: 16px;
			line-height: 44px;
			cursor: pointer;
		}
		.active {
			position: relative;
			margin-right: 30px;
			width: 64px;
			height: 44px;
			color: $color;
			font-size: 16px;
			line-height: 44px;
// border-bottom: 4px solid $color;
			cursor: pointer;
		}
		.active::after {
			position: absolute;
			top: 40px;
			left: 16px;
			width: 32px;
			height: 4px;
			background: $color;
			content: "";
		}
	}
}
</style>