<template>
  <div class="wrapper">
    <p class="account"><span>登录账号</span><span>{{loginInfo.username}}</span></p>
    <p class="yh_bandphone">
      <span>绑定手机</span>
      <span class="yh_phone">{{loginInfo.phone}}</span>
      <span class="changenumber" @click="changeNumber">绑定手机</span>
    </p>
    <p class="yh_updatepassword">
      <span>修改密码</span>
      <span>为了您账号的安全，我们建议您每三个月修改一次个人密码。</span>
      <span class="update" @click="changePassword">修改密码</span><!-- bg_lightGreen -->
    </p>
    <p class="updatetime">
      <span></span>
      <span class="tips">上次修改密码日期：{{loginInfo.updateTime}}</span>
    </p>
    <el-dialog
      class="dialog_wrapper"
      title="绑定手机"
      v-if="isChangeNumber"
      :visible.sync="isChangeNumber"
      :close-on-click-modal="false"
      width="500px"
    >
      <changePhone @closeDialog="closeDialog" />
    </el-dialog>
    <el-dialog
      class="dialog_wrapper"
      title="修改密码"
      v-if="isChangePassword"
      :visible.sync="isChangePassword"
      :close-on-click-modal="false"
      width="500px"
    >
      <change-password @showDialog="showDialog" />
    </el-dialog>
  </div>
</template>

<script>
import changePhone from './changePhone';
import changePassword from './changePassword';
export default {
  name: 'aSecurity',
  components: {
    changePhone,
    changePassword
  },
  data() {
    return {
      isChangeNumber: false,
      isChangePassword: false,
      loginInfo: {}
    }
  },
  mounted() {
    if(sessionStorage.getItem('profile')) {
      this.loginInfo = JSON.parse(sessionStorage.getItem('profile'))
    }
  },
  methods: {
    changeNumber() {
      this.isChangeNumber = !this.isChangeNumber;
    },
    changePassword() {
      this.isChangePassword = !this.isChangePassword;
    },
    closeDialog(closeDialog) {
      this.isChangeNumber = closeDialog;
      this.loginInfo = JSON.parse(sessionStorage.getItem('profile'))
    },
    showDialog(bool) {
      this.isChangePassword = bool
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
	.account {
		margin-bottom: 24px;
		:nth-of-type(1) {
			margin-right: 60px;
			color: #91929e;
			font-size: 16px;
			line-height: 22px;
		}
		:nth-of-type(2) {
			color: #0a1629;
			font-size: 16px;
			line-height: 22px;
		}
	}
	.yh_bandphone,
	.yh_updatepassword,
	.updatetime {
		margin-bottom: 24px;
		:nth-of-type(1) {
			margin-right: 60px;
			color: #91929e;
			font-size: 16px;
			line-height: 22px;
		}
		:nth-of-type(2) {
			color: #0a1629;
			font-size: 16px;
			line-height: 22px;
		}
    .yh_phone{
      display: inline-block;
      width: 500px;
    }
		.changenumber {
			display: inline-block;
			width: 90px;
			height: 30px;
			border-radius: 5px;
			// color: var(--colorBlue);
      color: #fff;
      background: #3f8cff;
			text-align: center;
			font-weight: bold;
			font-size: 13px;
			line-height: 30px;
			cursor: pointer;
		}
		.update {
			display: inline-block;
			width: 90px;
			height: 30px;
			border-radius: 5px;
			// color: var(--colorBlue);
      color: #fff;
      background: #3f8cff;
			text-align: center;
			font-weight: bold;
			font-size: 13px;
			line-height: 30px;
			cursor: pointer;
		}
		.tips {
			margin-left: 61px;
			color: #ced5e0;
			font-weight: 400;
			line-height: 22px;
		}
	}
	.yh_updatepassword {
		margin-bottom: 12px;
		:nth-of-type(2) {
			margin-right: 69px;
		}
	}
}
</style>
