<template>
  <div class="yh_wrapper">
      <el-form class="form" ref="ruleForm" :model="formData">
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户名">
              <el-input v-model="formData.realName" maxlength="30" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="性别">
              <el-radio-group v-model="formData.sex">
                <el-radio :label="1">女</el-radio>
                <el-radio :label="0">男</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="详细地址">
              <el-input v-model="formData.address"  maxlength="35" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="个性签名">
              <el-input v-model="formData.remark" maxlength="35" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="save">
        <el-button class="btn btn_blue" @click="save">保存</el-button>
      </div>
    </div>
</template>

<script>
import API from '@/apis/studentsEndAPI/personalCenterAPI';
import { EventBus } from '@/assets/js/eventBus'
export default {
  name: 'baseData',
  props: {
    baseInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formData: {
        realName: '',
        sex: null,
        avatar: '',
        address: '',
        remark: '',
      },
      profileObj: {}
    }
  },
  mounted() {
    if(this.baseInfo) {
      this.formData.realName = this.baseInfo.realName
      this.formData.avatar = this.baseInfo.avatar
      this.formData.address = this.baseInfo.address
      this.formData.remark = this.baseInfo.remark
      this.formData.sex = Number(this.baseInfo.sex)
    }
    EventBus.$on('url', val => {
      if(val) {
        this.formData.avatar = val
      }
    })
  },
  methods: {
    async save() {
      let data = await API.putProfile(this.formData)
      if(data && !data.status) {
        this.getProfile()

      }
    },
    async getProfile() {
      let data = await API.getProfile()
      this.profileObj = data
      this.$emit('closeDialog' ,false,this.profileObj)
      // if(data) {
      //   sessionStorage.setItem('profile',JSON.stringify(data))
      // }
    },
  }
}
</script>

<style lang="scss" scoped>
.save {
  // position: absolute;
  // right: 7px;
  // bottom: -34px;

  text-align: right;
}
</style>
