<template>
  <div>
    <el-form class="form" ref="ruleForm" :model="formData" :rules="rules">
      <el-row>
        <!-- 原密码 -->
        <!-- <el-col :span="24">
          <el-form-item prop="oldPassword" label="原密码">
            <el-input v-model="formData.oldPassword" placeholder="请输入旧密码" clearable></el-input>
          </el-form-item>
        </el-col> -->
        <!-- 新密码 -->
        <el-col :span="24">
          <el-form-item prop="newPassword" label="新密码">
            <el-input
              v-model="formData.newPassword"
              placeholder="请输入新密码"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="sureNewPassword" label="确认密码">
            <el-input
              v-model="formData.sureNewPassword"
              placeholder="请再次输入密码"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="dialog_submit">
        <el-button class="btn btn_cancle" @click="cancel">取消</el-button>
        <el-button class="btn btn_blue" @click="submit('ruleForm')"
          >确认</el-button
        >
      </div>
    </el-form>
  </div>
</template>
<script>
import API from "@/apis/studentsEndAPI/personalCenterAPI";
export default {
  name: "changePassword",
  data() {
    return {
      formData: {
        // oldPassword: "",
        newPassword: "",
        sureNewPassword: "",
      },
      rules: {
        // oldPassword: [
        //   { required: true, message: "请输入原密码", trigger: "blur" },
        // ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        sureNewPassword: [
          { required: true, validator: this.changePass, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submit(ruleForm) {
      this.$refs[ruleForm].validate(async (valid) => {
        if (valid) {
          let params = {
            // oldPassword: this.$md5(this.formData.oldPassword),
            newPassword: this.$md5(this.formData.newPassword),
          };
          API.changePassword(params).then(() => {
            this.$emit("showDialog", false);
          });
        }
      });
    },
    cancel() {
      this.$emit("showDialog", false);
    },
    changePass(rule, value, callback) {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formData.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    },
  },
};
</script>
