<template>
  <div class="wrapper">
    <p class="yh_user">
      <span class="yh_username">用户名</span>
      <span>{{this.baseInfo.realName | filterTitle }}</span>
    </p>
    <p class="yh_sex">
      <span>性别</span>
      <span class="sex">{{sex}}</span>
    </p>
    <p class="yh_address">
      <span>详细地址</span>
      <span class="detail">{{this.baseInfo.address}}</span>
    </p>
    <p class="yh_autograph">
      <span>个性签名</span>
      <span>{{this.baseInfo.remark}}</span>
    </p>
    <span class="yh_update bg_lightGreen" @click="isShowWrite">修改</span>
    <el-dialog
      class="dialog_wrapper"
      title="修改基本资料"
      v-if="isUpdate"
      :visible.sync="isUpdate"
      :close-on-click-modal="false"
      width="600px"
    >
      <base-data :baseInfo="baseInfo" @closeDialog="closeDialog" />
    </el-dialog>
    <!-- <div class="yh_data" v-show="isShowWrite">
      <el-form class="form" ref="ruleForm" :model="formData">
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户名">
              <el-input v-model="formData.realName" maxlength="30" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="性别">
              <el-radio-group v-model="formData.sex">
                <el-radio :label="1">女</el-radio>
                <el-radio :label="0">男</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="详细地址">
              <el-input v-model="formData.address"  maxlength="35" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="个性签名">
              <el-input v-model="formData.remark" maxlength="35" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span class="save bg_blue" @click="save">保存</span>
    </div> -->
  </div>
</template>

<script>
import baseData from './baseData'
export default {
  name: 'aData',
  components: {
    baseData
  },
  data() {
    return {
      sex: '',
      baseInfo: {},
      isUpdate: false,
    }
  },
  mounted() {
    if(sessionStorage.getItem('profile')) {
      this.baseInfo = JSON.parse(sessionStorage.getItem('profile'))
      if(this.baseInfo.sex == '0') {
        this.sex = '男'
      }else if(this.baseInfo.sex == '1') {
        this.sex = '女'
      }else {
        this.sex = '未知'
      }
    }
  },
  methods: {
    isShowWrite() {
      this.isUpdate = !this.isUpdate
    },
    closeDialog(closeDialog,profileObj) {
      this.isUpdate = closeDialog
      this.baseInfo = profileObj
    }
  },
  filters: {
    filterTitle(val) {
      if(!val) return ''
      return val.length > 15 ? (val.substr(0,15) + '...') : val
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
	position: relative;
	.yh_user,
	.yh_sex,
	.yh_unit,
	.yh_area,
	.yh_address,
	.yh_autograph {
		margin-bottom: 33px;

		:nth-of-type(1) {
			margin-right: 64px;
			color: #7d8592;
			line-height: 29px;
		}
		:nth-of-type(2) {
			color: #0a1629;
			font-size: 16px;
			line-height: 22px;
		}
		.sex {
			margin-left: 26px;
		}
		.detail {
			display: inline-block;
			overflow: hidden;
			width: 340px;
			height: 29px;
			vertical-align: middle;
			text-overflow: ellipsis;
			white-space: nowrap;
			line-height: 24px;
		}
	}
	.yh_user {
		.yh_username {
			margin-right: 76px;
		}
	}
	.yh_update {
		display: inline-block;
		margin-left: 88px;
		width: 64px;
		height: 30px;
		border-radius: 5px;
		//color: var(--colorBlue);
		color: #FFFFFF;
		text-align: center;
		font-weight: bold;
		font-size: 13px;
		line-height: 30px;
		cursor: pointer;
	}
	// .yh_data {
	// 	position: absolute;
	// 	top: -2%;
	// 	right: 0%;
		// .save {
		// 	position: absolute;
		// 	right: 7px;
		// 	bottom: -34px;
		// 	width: 64px;
		// 	height: 30px;
		// 	border-radius: 5px;
		// 	color: var(--colorBlue);
		// 	text-align: center;
		// 	font-weight: bold;
		// 	font-size: 13px;
		// 	line-height: 30px;
		// 	cursor: pointer;
		// }
	// }
}
.yh_form_item_select {
	width: 200px !important;
}
::v-deep {
	.form .el-form-item__content {
		padding-left: 74px;  //
	}
	.el-row {
		width: 520px;
	}
	.el-form-item__label {
		text-align: left;
	}
	.el-form-item {
		margin-bottom: 16px;
	}
	.el-radio__input.is-checked .el-radio__inner::after {
		position: absolute;
		top: 1px;
		left: 1px;
		display: block;
		width: 8px;
		height: 5px;
		border: 1px solid white;
		border-top: transparent;
		border-right: transparent;
		border-radius: 0;
		background: none;
		color: var(--colorBlue);
		content: "";
		vertical-align: middle;
		text-align: center;
		transform: rotate(-45deg);
	}
}
</style>
